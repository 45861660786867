

@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}

.slider {
	margin: 0px 60px;
	padding:auto;
	overflow:hidden;
	position: relative;
	width: auto;
	
	.slide-track {
		animation: scroll 40s linear infinite;
		display: flex;
		width: calc(250px * 16);
	}
	.slide {
		width: 300px;
		margin: 20px;
	}
}