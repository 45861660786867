.subContainer{
    border: 2px solid transparent;
    height: 70%;
    width: 70%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition: all 0.3s ease-in-out 0s;
}

.top{
    opacity: 0;
    margin-bottom: -20px;
    color:#fff;
    transition: all 0.3s ease-in-out 0s;
}
.desc{
    text-align: center;
    color: #fff;
    margin-top: -20px;
    opacity: 0;
    font-size: 14px;
    transition: all 0.3s ease-in-out 0s;
}
.mainContainer:hover .subContainer{
    border: 2px solid #ffffff;
    height: 80%;
    width: 80%;
}

.mainContainer:hover .desc{
    margin-top: 0px;
    opacity: 1;
}
.mainContainer:hover .top{
    margin-bottom: 0px;
    opacity: 1;
}